import React, { useState, useEffect, useRef } from 'react';
import { Share2, Copy, MessageSquare, Twitter, Instagram } from 'lucide-react';
import { Button } from './components/ui/button';
import { foodData, pairingRules } from './data/foodData';

const getRandomColor = () => {
  const hue = Math.floor(Math.random() * 360);
  return `hsl(${hue}, 100%, 75%)`;
};

const getRandomItem = (array) => array[Math.floor(Math.random() * array.length)];

const App = () => {
  const [pairing, setPairing] = useState([]);
  const [nextPairing, setNextPairing] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState(getRandomColor());
  const [isSpinning, setIsSpinning] = useState(false);
  const canvasRef = useRef(null);

  useEffect(() => {
    generateNewPairing();
  }, []);

  const generateNewPairing = () => {
    if (isSpinning) return;
    setIsSpinning(true);

    const selectedRule = getRandomItem(pairingRules);
    const food1Array = foodData.filter(food => food.category === selectedRule.category1);
    const food2Array = foodData.filter(food => food.category === selectedRule.category2);

    if (food1Array.length === 0 || food2Array.length === 0) {
      console.error('No foods found for selected categories');
      setIsSpinning(false);
      return;
    }

    const food1 = getRandomItem(food1Array);
    let food2;
    do {
      food2 = getRandomItem(food2Array);
    } while (food2.name === food1.name && food2Array.length > 1);

    setNextPairing([food1, food2]);
    setBackgroundColor(getRandomColor());

    setTimeout(() => {
      setPairing([food1, food2]);
      setIsSpinning(false);
    }, 500);
  };

  const generateShareText = () => {
    return `I WANT ${pairing[0]?.name} and ${pairing[1]?.name}. TOGETHER. NOW! #blamethebump areyoucravingthis.com`;
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(generateShareText())
      .then(() => alert('Copied to clipboard!'))
      .catch(err => console.error('Failed to copy: ', err));
  };

  const handleSendSMS = () => {
    const text = encodeURIComponent(generateShareText());
    window.open(`sms:?&body=${text}`, '_blank');
  };

  const handleShareToSocialMedia = (platform) => {
    const text = encodeURIComponent(generateShareText());
    const url = encodeURIComponent(window.location.href);
    let shareUrl;

    switch(platform) {
      case 'pinterest':
        shareUrl = `https://pinterest.com/pin/create/button/?url=${url}&description=${text}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?text=${text}&url=${url}`;
        break;
      case 'reddit':
        shareUrl = `https://reddit.com/submit?url=${url}&title=${text}`;
        break;
      default:
        console.error('Unsupported platform');
        return;
    }

    window.open(shareUrl, '_blank');
  };

  const generateImage = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = 1080;
    const height = 1080;

    canvas.width = width;
    canvas.height = height;

    // Background
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, width, height);

    // Text
    ctx.fillStyle = 'white';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
    ctx.shadowBlur = 10;

    // Title
    ctx.font = 'bold 60px Arial';
    ctx.fillText('Food Pairing', width / 2, 100);

    // Foods
    ctx.font = 'bold 80px Arial';
    ctx.fillText(pairing[0]?.name, width / 2, height / 2 - 100);
    ctx.font = 'bold 60px Arial';
    ctx.fillText('&', width / 2, height / 2);
    ctx.font = 'bold 80px Arial';
    ctx.fillText(pairing[1]?.name, width / 2, height / 2 + 100);

    // Footer
    ctx.font = '30px Arial';
    ctx.fillText('Generated by Food Pairing App', width / 2, height - 50);

    return canvas.toDataURL('image/png');
  };

  const handleInstagramShare = () => {
    const imageData = generateImage();
    const link = document.createElement('a');
    link.href = imageData;
    link.download = 'food-pairing.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    alert('Image downloaded. You can now share it on Instagram!');
  };

  return (
    <div 
      className="min-h-screen flex flex-col items-center justify-between p-4 text-center transition-colors duration-500 ease-in-out overflow-hidden"
      style={{ backgroundColor }}
    >
      <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mt-4 mb-2 text-white shadow-text">Are you craving...</h1>
      
      <div className="flex-grow flex items-center justify-center w-full max-w-4xl mx-auto px-4">
        <div className="carousel-container perspective w-full h-full">
          <div className={`carousel ${isSpinning ? 'spinning' : ''}`}>
            <div className="carousel-face front">
              <h2 className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-bold mb-2 text-white shadow-text leading-tight">{pairing[0]?.name || 'Loading...'}</h2>
              <h3 className="text-4xl sm:text-5xl md:text-6xl mb-2 text-white shadow-text">&</h3>
              <h2 className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-bold text-white shadow-text leading-tight">{pairing[1]?.name || 'Loading...'}</h2>
            </div>
            <div className="carousel-face back">
              <h2 className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-bold mb-2 text-white shadow-text leading-tight">{nextPairing[0]?.name || 'Loading...'}</h2>
              <h3 className="text-4xl sm:text-5xl md:text-6xl mb-2 text-white shadow-text">&</h3>
              <h2 className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-bold text-white shadow-text leading-tight">{nextPairing[1]?.name || 'Loading...'}</h2>
            </div>
          </div>
        </div>
      </div>
      
      <div className="flex flex-col space-y-4 mb-4 mt-4 w-full">
        <Button 
          onClick={generateNewPairing}
          className="bg-white text-black hover:bg-gray-200 text-xl sm:text-2xl md:text-3xl font-bold py-3 px-6 rounded-full shadow-lg transform transition duration-200 hover:scale-105 mx-auto"
          disabled={isSpinning}
        >
          Generate New Pairing
        </Button>
        
        <div className="flex flex-wrap justify-center gap-2 sm:gap-4">
          <Button onClick={handleCopyToClipboard} className="bg-pink-400 text-white hover:bg-pink-500 rounded-full px-4 py-2 flex items-center shadow-md transition duration-200 hover:scale-105 text-sm sm:text-base md:text-lg">
            <Copy className="mr-2 h-4 w-4 sm:h-5 sm:w-5" /> Copy
          </Button>
          <Button onClick={handleSendSMS} className="bg-green-400 text-white hover:bg-green-500 rounded-full px-4 py-2 flex items-center shadow-md transition duration-200 hover:scale-105 text-sm sm:text-base md:text-lg">
            <MessageSquare className="mr-2 h-4 w-4 sm:h-5 sm:w-5" /> SMS
          </Button>
          <Button onClick={() => handleShareToSocialMedia('pinterest')} className="bg-red-400 text-white hover:bg-red-500 rounded-full px-4 py-2 flex items-center shadow-md transition duration-200 hover:scale-105 text-sm sm:text-base md:text-lg">
            <Share2 className="mr-2 h-4 w-4 sm:h-5 sm:w-5" /> Pinterest
          </Button>
          <Button onClick={() => handleShareToSocialMedia('twitter')} className="bg-blue-400 text-white hover:bg-blue-500 rounded-full px-4 py-2 flex items-center shadow-md transition duration-200 hover:scale-105 text-sm sm:text-base md:text-lg">
            <Twitter className="mr-2 h-4 w-4 sm:h-5 sm:w-5" /> Twitter
          </Button>
          <Button onClick={() => handleShareToSocialMedia('reddit')} className="bg-orange-400 text-white hover:bg-orange-500 rounded-full px-4 py-2 flex items-center shadow-md transition duration-200 hover:scale-105 text-sm sm:text-base md:text-lg">
            <Share2 className="mr-2 h-4 w-4 sm:h-5 sm:w-5" /> Reddit
          </Button>
          <Button onClick={handleInstagramShare} className="bg-purple-400 text-white hover:bg-purple-500 rounded-full px-4 py-2 flex items-center shadow-md transition duration-200 hover:scale-105 text-sm sm:text-base md:text-lg">
            <Instagram className="mr-2 h-4 w-4 sm:h-5 sm:w-5" /> Instagram
          </Button>
        </div>
      </div>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
};

export default App;