// src/data/foodData.js

export const foodData = [
  // Fruits
  ...['Apples', 'Oranges', 'Grapes', 'Strawberries', 'Blueberries', 'Raspberries',
    'Lemons', 'Limes', 'Peaches', 'Plums', 'Pears', 'Watermelon', 'Honeydew Melon',
    'Cantaloupe', 'Pineapple', 'Avocado', 'Bananas', 'Cherries', 'Grapefruit',
    'Mangoes', 'Kiwis', 'Papayas', 'Pomegranates', 'Figs', 'Dates', 'Apricots',
    'Cranberries', 'Blackberries', 'Coconuts'].map(name => ({
      name,
      category: 'Fruit',
      safeForPregnancy: true
    })),

  // Vegetables
  ...['Lettuce', 'Tomatoes', 'Cucumbers', 'Carrots', 'Bell Peppers', 'Spinach',
    'Broccoli', 'Mushrooms', 'Zucchini', 'Celery', 'Green Onions', 'Radishes',
    'Cauliflower', 'Kale', 'Cabbage', 'Corn on the Cob', 'Green Beans', 'Fresh Peas',
    'Eggplant', 'Asparagus', 'Brussels Sprouts', 'Okra', 'Butternut Squash',
    'Acorn Squash', 'Spaghetti Squash', 'Pumpkin', 'Watercress', 'Leeks', 'Onions',
    'Beets', 'Turnips', 'Parsnips', 'Rutabaga', 'Sweet Potatoes', 'Potatoes', 'Yams'].map(name => ({
      name,
      category: 'Vegetable',
      safeForPregnancy: true
    })),

  // Proteins
  ...['Ground Beef', 'Chicken Breasts', 'Salmon Fillets', 'Shrimp',
    'Tofu', 'Tempeh', 'Seitan', 'Eggs', 'Ground Turkey', 'Steak Cuts', 'Lamb Chops',
    'Beans', 'Lentils', 'Chickpeas', 'Black Beans', 'Kidney Beans', 'Pinto Beans',
    'Navy Beans', 'Cannellini Beans', 'Adzuki Beans', 'Split Peas', 'Edamame'].map(name => ({
      name,
      category: 'Protein',
      safeForPregnancy: true
    })),

  // Dairy and Alternatives
  ...['Milk', 'Butter', 'Cheddar Cheese', 'Yogurt', 'Sour Cream', 'Cream Cheese',
    'Heavy Cream', 'Cottage Cheese', 'Ricotta Cheese', 'Parmesan Cheese',
    'Mozzarella Cheese', 'Hard Cheeses', 'Buttermilk', 'Half and Half', 'Whipping Cream',
    'Almond Milk', 'Soy Milk', 'Coconut Milk', 'Oat Milk', 'Rice Milk', 'Hemp Milk',
    'Cashew Milk', 'Coconut Yogurt', 'Vegan Cheese'].map(name => ({
      name,
      category: 'Dairy',
      safeForPregnancy: true
    })),

  // Grains and Breads
  ...['Tortillas', 'Whole Wheat Bread', 'Bagels', 'English Muffins', 'Fresh Pasta',
    'ookie Dough', 'Rice', 'Cornmeal',
    'Rolled Oats', 'Quinoa', 'Couscous',
     'Granola', 'Breakfast Cereals', 'Noodles', 'Bread Crumbs',
    'Pita Bread', 'Naan Bread', 'Hot Dog Buns', 'Sourdough'].map(name => ({
      name,
      category: 'Grain',
      safeForPregnancy: true
    })),

  // Nuts and Seeds
  ...['Almonds', 'Cashews', 'Peanuts', 'Pistachios', 'Walnuts', 'Pecans',
    'Hazelnuts', 'Macadamia Nuts', 'Sunflower Seeds', 'Pumpkin Seeds',
    'Chia Seeds', 'Flaxseeds', 'Sesame Seeds', 'Almond Butter', 'Peanut Butter',
    'Tahini'].map(name => ({
      name,
      category: 'Nuts and Seeds',
      safeForPregnancy: true
    })),

  // Condiments and Sauces
  ...['Pickles', 'Mayonnaise', 'Ketchup', 'Mustard', 'Barbecue Sauce', 'Salad Dressing',
    'Pesto Sauce', 'Tomato Sauce', 'Salsa', 'Guacamole', 'Hummus', 'Soy Sauce',
    'Hot Sauce', 'Worcestershire Sauce', 'Vinegar', 'Fish Sauce', 'Teriyaki Sauce',
    'Hoisin Sauce', 'Maple Syrup', 'Honey', 'Jam', 'Jelly', 'Molasses',
    'Agave Nectar', 'Caramel'].map(name => ({
      name,
      category: 'Condiment',
      safeForPregnancy: true
    })),

  // Herbs and Spices
  ...['Parsley', 'Cilantro', 'Basil', 'Rosemary', 'Thyme', 'Oregano', 'Sage', 'Dill',
    'Mint', 'Chives', 'Bay Leaves', 'Cinnamon', 'Nutmeg', 'Cumin', 'Paprika',
    'Chili Powder', 'Black Pepper', 'Salt', 'Turmeric', 'Ginger', 'Garlic',
    'Saffron'].map(name => ({
      name,
      category: 'Herb or Spice',
      safeForPregnancy: true
    })),

  // Snacks and Sweets
  ...['Chocolate Chip Cookies', 'Oatmeal Raisin Cookies', 'Sugar Cookies',
    'Brownies', 'Vanilla Ice Cream', 'Chocolate Ice Cream', 'Potato Chips',
    'Tortilla Chips', 'Popcorn', 'Trail Mix', 'Granola Bars', 'Dried Fruit',
    'Crackers', 'Greek Yogurt', 'Dark Chocolate Squares', 'Fruit Smoothies',
    'Apple Sauce', 'Chocolate Chips'].map(name => ({
      name,
      category: 'Snack',
      safeForPregnancy: true
    })),

  // Beverages
  ...['Orange Juice', 'Apple Juice', 'Cranberry Juice', 'Grape Juice',
    'Sparkling Water', 'Fruit Smoothies'].map(name => ({
      name,
      category: 'Beverage',
      safeForPregnancy: true
    })),

  // Others
  ...['Olive Oil', 'Coconut Oil',
    'Sugar', 'Vanilla Extract', 'Cocoa Powder', 'Seaweed Snacks',
    'Miso Paste', 'Curry Seasoning', 'Kimchi', 'Sauerkraut', 'Dried Mushrooms',
    'Zucchini Noodles', 'Cauliflower Rice', 'Broth', 'Stock'].map(name => ({
      name,
      category: 'Other',
      safeForPregnancy: true
    }))
];

// Pairing rules
export const pairingRules = [
  { category1: 'Fruit', category2: 'Dairy' },
  { category1: 'Fruit', category2: 'Snack' },
  { category1: 'Vegetable', category2: 'Protein' },
  { category1: 'Grain', category2: 'Protein' },
  { category1: 'Vegetable', category2: 'Grain' },
  { category1: 'Protein', category2: 'Dairy' },
  { category1: 'Nuts and Seeds', category2: 'Fruit' },
  { category1: 'Nuts and Seeds', category2: 'Snack' },
  { category1: 'Fruit', category2: 'Fruit' },
  { category1: 'Vegetable', category2: 'Vegetable' },
  { category1: 'Dairy', category2: 'Grain' },
  { category1: 'Protein', category2: 'Grain' },
  { category1: 'Condiment', category2: 'Protein' },
  { category1: 'Condiment', category2: 'Vegetable' },
  { category1: 'Condiment', category2: 'Grain' },
  { category1: 'Snack', category2: 'Dairy' },
  { category1: 'Fruit', category2: 'Vegetable' },
  { category1: 'Protein', category2: 'Vegetable' }
];